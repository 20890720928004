/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* purgecss end ignore */
@tailwind utilities;

.component-selected {
  @apply relative;
}
.component-selected::after {
  content: ' ';
  @apply border-primary border border-dashed w-full h-full absolute left-0 top-0 pointer-events-none block;
}

.transition {
  transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}

/* Keep some preflight */
blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

button#next-button {
  animation: fadeIn 1s;
  animation-delay: 3s;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

#select-event-form-control {
  margin: 0px;
}
